export default defineI18nConfig(() => ({
    legacy: false,
    // datetimeFormats,
    locale: 'nl',
    globalInjection: true,
    inheritLocale: true,
    fallbackLocale: 'nl',
    missingWarn: false,
    fallbackWarn: false,
}));
